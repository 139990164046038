import {
  QueryPaginateSubscriptionPaymentsArgs,
  SubscriptionPaymentPagination,
  SubscriptionPaymentPaginationInput,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { SUBSCRIPTION_PAYMENT_FIELDS } from "@/graphql/subscription/subscription.payment";
import { useQuery, useResult } from "@vue/apollo-composable";
import { InitialPagination, PAGINATION_META } from "@/graphql/utils/pagination";
import { reactive, ref } from "vue";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import moment from "moment";

type PaginateSubscriptionPaymentsData = {
  paginateSubscriptionPayments: SubscriptionPaymentPagination;
};

const PAGINATE_SUBSCRIPTION_PAYMENTS = gql`
    query PaginateSubscriptionPayments($input: SubscriptionPaymentPaginationInput!){
       paginateSubscriptionPayments(input: $input) {
            items{
                ${SUBSCRIPTION_PAYMENT_FIELDS}
                subscription{duration}
                user{id name}
                activity{id name}
            }
            ${PAGINATION_META}
        }
    }
`;

const FILTERS_ = {
  "activity.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  "user.name": {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  transactionId: {
    operator: FilterOperator.AND,
    constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }],
  },
  purchasedAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  expiredAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  amount: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export const usePaginateSubscriptionPayments = () => {
  const filters = ref(FILTERS_);
  const date = new Date();
  const input: SubscriptionPaymentPaginationInput = reactive({
    keyword: "",
    startAt: moment(date).add(-1, "days").toDate(),
    endAt: date,
    page: 1,
    limit: 40,
  });
  function findByKeywordInput() {
    return {
      page: input.page,
      limit: input.limit,
      keyword: input.keyword,
      startAt: null,
      endAt: null,
    };
  }
  const { loading, result, refetch } = useQuery<
    PaginateSubscriptionPaymentsData,
    QueryPaginateSubscriptionPaymentsArgs
  >(PAGINATE_SUBSCRIPTION_PAYMENTS, { input });
  const payments = useResult<
    PaginateSubscriptionPaymentsData,
    SubscriptionPaymentPagination,
    SubscriptionPaymentPagination
  >(result, InitialPagination, (res) => {
    return {
      ...res.paginateSubscriptionPayments,
      items: res.paginateSubscriptionPayments.items.map((s) => ({
        ...s,
        expiredAt: new Date(s.expiredAt),
        purchasedAt: new Date(s.purchasedAt),
      })),
    };
  });

  function clearFilter() {
    Object.assign(filters.value, FILTERS_);
  }
  function findByKeyword() {
    void refetch({
      input: findByKeywordInput(),
    });
  }
  function findByDates() {
    void refetch({
      input: {
        ...findByKeywordInput(),
        keyword: "",
        startAt: input.startAt,
        endAt: input.endAt,
      },
    });
  }
  return {
    loading,
    payments,
    input,
    clearFilter,
    filters,
    findByKeyword,
    findByDates,
  };
};
