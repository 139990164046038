
import { defineComponent } from "vue";
import { usePaginateSubscriptionPayments } from "@/graphql/subscription/paginate-subscription-payments";
import { numberSeparators } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";

export default defineComponent({
  name: "SubscriptionPayment",
  setup() {
    return {
      ...usePaginateSubscriptionPayments(),
      numberSeparators,
      activeActivity,
    };
  },
});
